
import { 
  IonContent, IonHeader, IonPage, IonCardTitle, IonCardSubtitle, IonToolbar,
  IonCard, IonCardHeader, IonCardContent, IonButtons, IonButton, IonIcon, IonItem,
  IonLabel, IonInput, IonNote, IonFooter, isPlatform, alertController, IonImg
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { useVuelidate } from '@vuelidate/core';
import { email, required, minLength } from '@vuelidate/validators';
import { createNamespacedHelpers } from 'vuex';
import { ToastType } from '@/store/toast.store';

const authStoreHelper = createNamespacedHelpers('auth')
const toastStoreHelper = createNamespacedHelpers('toast')
const loaderStoreHelper = createNamespacedHelpers('loader')

const passwordMinLength = 5

export default defineComponent({
  name: 'LoginPage',

  components: {
    IonContent, IonHeader, IonPage, IonToolbar, IonButtons, IonButton, IonFooter,
    IonIcon, IonItem, IonLabel, IonInput, IonNote, IonCard, IonCardContent, IonCardHeader,
    IonCardTitle, IonCardSubtitle, IonImg
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      email: '',
      password: '',
      logoImage: require('@/assets/images/logo.jpg'),
      isShowingPassword: false,
  
      isIos: isPlatform('ios'),
     
      // Ion icons
      closeOutline, eyeOffOutline, eyeOutline,
      
      passwordMinLength
    }
  },

  computed: {
    ...authStoreHelper.mapState(['user']),
  },

  methods: {
    ...authStoreHelper.mapActions(['emailSignIn', 'sendEmailPasswordRecovery']),
    ...toastStoreHelper.mapActions({ presentToast: 'present' }),
    ...loaderStoreHelper.mapActions({ presentLoader: 'present', dismissLoader: 'dismiss' }),

    onTogglePasswordVisibility () {
      this.isShowingPassword = !this.isShowingPassword
    },
    onNext (event: any) {
      console.debug('AuthPage - onNext')
      const targetName = event.currentTarget.name
      if (targetName === 'email') {
        (this.$refs as any).password.$el.children[0].focus()
      } else if (targetName === 'password') {
        this.onFormSubmit()
      }
    },
    async onFormSubmit () {
      console.debug('AuthPage - onFormSubmit - start')
      await this.presentLoader()

      // Validate the fields
      this.v$.$touch()
      if (this.v$.$error) {
        console.debug('AuthPage - onFormSubmit - error')
        await this.dismissLoader()
        
        return
      }

      const result = await this.emailSignIn({ email: this.email, password: this.password })
      console.debug('AuthPage - onFormSubmit - done', { result })

      await this.dismissLoader()

      console.log('AuthPage - onFormSubmit', result)

      if(!result) {
        const alert = await alertController.create({
            header: 'Errore',
            message: `Le credenziali inserite non sono valide o si è verificato un errore inatteso. Prego riprovare!`,
            buttons: [{
              text: 'Ho capito',
              handler: () => {
                alert.dismiss()
              }
            }],
          })

          return alert.present()
      }

      return
    },
    async onPasswordRecovery () {
      const alert = await alertController.create({
          header: 'Recupero password',
          message: 'Inserisci la mail del tuo account per eseguire il reset della password.',
          buttons: [
            ({
              text: 'Chiudi',
              role: 'cancel'
            }), 
            ({
              text: 'Invia',
              handler: async (value: any) => { 
                const email = value[0]
                const validate = String(email)
                  .toLowerCase()
                  .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  );
                
                if (validate) {
                  const result = await this.sendEmailPasswordRecovery({email: email})

                  await this.presentToast(
                    result
                      ? { message: "La mail è stata inviata correttamente.", type: ToastType.success }
                      : { message: "Si è verificato un errore. Riprova più tardi.", type: ToastType.error }
                  )
                }
              }
            })
          ],
          inputs: [
            {
              placeholder: 'Inserisci la tua email',
            },
          ],
        });

        await alert.present();
    },
  },

  validations () {
    return {
      email: { required, email },
      password: { required, minLength: minLength(5) }
    }
  },
});
