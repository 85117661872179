import { Module } from 'vuex'
import { isPlatform, toastController } from '@ionic/vue';

export interface IToastStoreState {
  toast?: HTMLIonToastElement | undefined
}

export enum ToastType {
  error, success, warning, info,
}

export const ToastStore: Module<IToastStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    toast: undefined
  },

  mutations: {
    setValue (state, toast) {
      console.debug('ToastStore - setValue', { toast })
      state.toast = toast
    }
  },

  actions: {
    async present({ commit, dispatch, state }, params: { message: string; type?: ToastType; duration?: number }) {
      console.debug('ToastStore - present', { params })

      if (state.toast !== undefined) {
        dispatch('dismiss')
      }

      let color = 'dark'
      switch (params.type) {
        case ToastType.error:
          color = 'danger'
          break;
        case ToastType.warning:
          color = 'warning'
          break;
        case ToastType.success:
          color = 'success'
          break;
      }

      const toast = await toastController
        .create({
          message: params.message,
          color,
          animated: true,
          duration: params.duration != undefined ? params.duration : 3000,
          position: isPlatform('ios') ? 'top' : 'bottom'
        })
      commit('setValue', toast)
      toast.present()
    },

    async dismiss () {
      console.debug('ToastStore - dismiss')
      await toastController.dismiss()
    }
  }
}
