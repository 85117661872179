
import { defineComponent } from 'vue';
import { IonIcon, IonButton, IonImg } from '@ionic/vue';
import { close } from 'ionicons/icons';

export default defineComponent({
  name: 'InstallBanner',

  components: {
    IonIcon, IonButton, IonImg
  },


  data() {
    return {
      showInstallBanner: false,
      isSafariMobileiOS: false,
      isChromeAndroid: false,
      deferredPrompt: null as Event | null,
      logoImage: require('@/assets/images/logo02.jpg'),
      share: require('@/assets/images/share.png'),
      add: require('@/assets/images/add.png'),
      launch: require('@/assets/images/launch.png'),

      // Ion icons
      close
    };
  },

  mounted() {
    window.addEventListener("beforeinstallprompt", e => {
      console.debug('App - beforeinstallprompt')
      e.preventDefault()
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e
    })
    window.addEventListener("appinstalled", () => {
      console.debug('App - appinstalled')
      this.deferredPrompt = null;
    })

    const isInStandaloneMode = () =>
      (window.matchMedia('(display-mode: standalone)').matches) || ((window.navigator as any).standalone) || document.referrer.includes('android-app://');

    console.log("App - isInStandaloneMode" , isInStandaloneMode())

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream
    this.isSafariMobileiOS = isSafari && isIos

    if (!isInStandaloneMode() && this.isMobileDevice() && !this.isSafariMobileiOS) {
      this.showInstallBanner = true;
    }
  },

  methods: {
    dismissInstallBanner() {
      this.showInstallBanner = false;
    },

    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test((window.navigator as any).userAgent);
    },

    dismiss() {
      console.debug('App - beforeinstallprompt - dismiss')
      this.deferredPrompt = null
    },
    
    install () {
      if (this.deferredPrompt != null) {
        (this.deferredPrompt as any).prompt()
      }
    }
  }
})
