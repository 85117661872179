
import { IonContent, IonHeader, IonPage, IonTitle, IonButtons, IonToolbar, IonIcon, IonButton, IonLabel, alertController, IonImg } from '@ionic/vue';
import { defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { logOut, helpCircleSharp } from 'ionicons/icons';
import * as jwt from 'jose';
import CameraScanner from '../components/CameraScanner.vue';
import { DevicePlatform, IDeviceModel, IUserModel } from '@esse-group/shared';
import { ToastType } from '@/store/toast.store';
import pkgInfo from '../../package.json'

const authStoreHelper = createNamespacedHelpers('auth')
const toastStoreHelper = createNamespacedHelpers('toast')
const loaderStoreHelper = createNamespacedHelpers('loader')
const deviceStoreHelper = createNamespacedHelpers('device')

export default defineComponent({
  name: 'HomePage',

  components: { 
    IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon, IonButtons, IonButton, IonLabel, CameraScanner, IonImg
  },

  computed: {
    ...authStoreHelper.mapState(['user']),
    ...deviceStoreHelper.mapState(['device']),
  },

  data () {
    return {
      isEnabled: false,
      isOnboarding: false,
      appVersion: pkgInfo.version,
      cameraEnable: false,
      switchOnboardingStatus: 'android',
      onboardingAndroid: require('@/assets/images/onboarding-android.jpg'),
      onboardingIos: require('@/assets/images/onboarding-ios.jpeg'),
      latitude: 0,
      longitude: 0,
      accuracy: 0,

      // Ion icons
      logOut, 
      helpCircleSharp,
    }
  },

  ionViewWillEnter() {
    const onboarding = (localStorage.getItem('onboarding') === 'true')
    const platform = (this.device as IDeviceModel).platform

    if (platform == DevicePlatform.IOS || platform == DevicePlatform.MACOS) {
      this.switchOnboardingStatus = 'ios'
    } else {
      this.switchOnboardingStatus = 'android'
    }

    if (!onboarding) {
      this.isOnboarding = true
      this.cameraEnable = false
    } else {
      this.cameraEnable = true
      this.getLocation()
    }

  },

  ionViewWillLeave() {
    this.cameraEnable = false
  },

  methods: {
    ...authStoreHelper.mapActions(['signOut', 'createClockingIn']),
    ...toastStoreHelper.mapActions({ presentToast: 'present' }),
    ...loaderStoreHelper.mapActions({ presentLoader: 'present', dismissLoader: 'dismiss' }),

    async onRequireCameraPermissions () {
      console.debug('onRequireCameraPermissions')
      
      location.reload()
      this.cameraEnable = true
      this.getLocation()
    },

    async onCloseOnBoarding() {
      console.debug('onCloseOnBoarding')
      
      location.reload()
      this.isOnboarding = false
      this.getLocation()

      localStorage.setItem('onboarding', 'true');
    },

    async onChangeSwitchOnboarding(value: CustomEvent) {
      console.log('onChangeSwitchOnboarding', value.detail.value)

      this.switchOnboardingStatus = value.detail.value
    },

    async onHelpClick() {
      this.cameraEnable = false
      this.isEnabled = false
      this.isOnboarding = true
    },

    async onLogoutClick () {
      const alert = await alertController.create({
        header: 'Logout',
        message: 'Sei sicuro di voler effettuare il logout?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              alert.dismiss()
            }
          },
          {
            text: 'Ok',
            role: 'confirm',
            handler: () => {
              // Logout
              this.signOut()
            }
          }
        ],
      })

      await alert.present()
    },

    cameraIsEnable () {
      this.isEnabled = true
    },

    async getLocation() {
      console.log('Navigator Geolocation', navigator.geolocation)
      if (navigator.geolocation) {
        navigator.geolocation.watchPosition(this.showPosition, this.errorPosition, { enableHighAccuracy: true });
      } else {
        await this.presentToast(
          { message: "Si è verificato un errore.", type: ToastType.warning }
        )
      }
    },

    async errorPosition(err: GeolocationPositionError) {
      console.log('Error Geolocation', err)

      const alert = await alertController.create({
        header: 'Errore',
        message: `${err.message}`,
        buttons: [{
          text: 'Ok',
          handler: () => {
            this.cameraEnable = true
          }
        }],
      })

      return alert.present()
    },

    showPosition(position: GeolocationPosition) {
      console.log("Latitude: ", position.coords.latitude)
      console.log("Longitude: ", position.coords.longitude)
      console.log("Accuracy: " + position.coords.accuracy + " meters.");

      this.latitude = position.coords.latitude
      this.longitude = position.coords.longitude
      this.accuracy = position.coords.accuracy
    },

    getDistanceFromLatLonInKm(lat1: any,lon1: any,lat2: any,lon2: any) {
      const R = 6371e3; // metres
      const φ1 = lat1 * Math.PI/180; // φ, λ in radians
      const φ2 = lat2 * Math.PI/180;
      const Δφ = (lat2-lat1) * Math.PI/180;
      const Δλ = (lon2-lon1) * Math.PI/180;

      const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ/2) * Math.sin(Δλ/2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

      return (R * c)/1000; // in km
      // const dLat: number = this.deg2rad(lat2-lat1) // deg2rad below
      // const dLon: number = this.deg2rad(lon2-lon1) 
      // const a: number = 
      //   Math.sin(dLat/2) * Math.sin(dLat/2) +
      //   Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
      //   Math.sin(dLon/2) * Math.sin(dLon/2)
      // const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)) 
      // const d: number = R * c // Distance in km
      // return d;
    },

    deg2rad(deg: number) {
      return deg * (Math.PI/180)
    },

    async decodeQRCode(value: string) {
      console.log('HomePage - start decode qr code')

      this.cameraEnable = false

      const url = new URL(value);
      const jwtString = url.searchParams.get("qr");

      console.log('HomePage - get jwt by url', jwtString)

      const qrCodePublicKey = (this.user as IUserModel).qrCodePublicKey

      if (qrCodePublicKey != undefined && qrCodePublicKey != null && jwtString != undefined && jwtString != null) {
        const clockingInInfo = jwt.decodeJwt(jwtString)
        await this.getLocation()

        console.log('HomePage - decode JWT', clockingInInfo)
        console.log(`HomePage - cordinate1 ${this.latitude}, ${this.longitude}`)
        console.log(`HomePage - cordinate2 ${clockingInInfo.lat}, ${clockingInInfo.lng}`)
        console.log('HomePage - get distance from lat lon in Km', this.getDistanceFromLatLonInKm(this.latitude, this.longitude, clockingInInfo.lat, clockingInInfo.lng))

        if (this.accuracy > 50) {
          const alertGeolocation = await alertController.create({
            header: 'Errore',
            message: `La precisione del segnale GPS non è sufficiente per poter eseguire correttamente la timbratura. Spostati di qualche metro.`,
            buttons: [{
              text: 'Ho capito',
              handler: () => {
                this.cameraEnable = true
              }
            }],
          })

          return alertGeolocation.present()
        }

        if (this.getDistanceFromLatLonInKm(this.latitude, this.longitude, clockingInInfo.lat, clockingInInfo.lng) > 0.1) {
          const alert = await alertController.create({
            header: 'Attenzione',
            message: `Ti trovi fuori dal raggio di 100 metri per la timbratura. La timbratura non può esser effettuata. L'accuratezza della tua posizione è di ${this.accuracy}.`,
            buttons: [{
              text: 'Ho capito',
              handler: () => {
                this.cameraEnable = true
              }
            }],
          })

          return alert.present()
        }

        const startDate = new Date()
        
        const alert = await alertController.create({
          header: 'Tipo di timbratura',
          subHeader: 'Seleziona la tipologia di timbratura che stai effettuando.',
          buttons: [{
            text: 'Annulla',
            handler: () => {
              alert.dismiss()
              this.cameraEnable = true
            }
          },{
            text: 'Invia',
            handler: async (value: any) => {
              console.log('ClockingInPage - ', value)

              await this.presentLoader()

              if (value == undefined || (value !== 'exit' && value !== 'entry')) {
                await this.presentToast(
                  { message: "Seleziona il tipo di ingresso", type: ToastType.warning }
                )

                alert.dismiss()

                await this.dismissLoader()

                this.cameraEnable = true
                return
              }

              const endDate = new Date()
              const diff = Math.abs(startDate.getTime() - endDate.getTime()) / 60000

              if (diff > 1) {
                await this.presentToast(
                  { message: "Timbratura scaduta.", type: ToastType.warning }
                )

                alert.dismiss()

                await this.dismissLoader()

                this.cameraEnable = true
                return
              }

              if (clockingInInfo != undefined) {
                  const result = await this.createClockingIn({ qrCodeWorkingSite: clockingInInfo, isClockingOut: value === 'exit', userId: this.user.id })

                if (result) {
                  // this.$router.push(`home/clocking-in`)
                  await this.presentToast(
                    { message: "Timbratura effettuata correttamente", type: ToastType.success }
                  )
                } else {
                  await this.presentToast(
                    { message: "Si è verificato un errore nella timbratura", type: ToastType.warning }
                  )
                }
              }

              alert.dismiss()
              await this.dismissLoader()

              this.cameraEnable = true
            }
          }],
          inputs: [
            {
              label: 'Ingresso',
              type: 'radio',
              value: 'entry',
            },
            {
              label: 'Uscita',
              type: 'radio',
              value: 'exit',
            },
          ],
        });

        await alert.present()
      } else {
        await this.presentToast({ message: "Si è verificato un errore. Riprova più tardi.", type: ToastType.error })
        this.cameraEnable = true
      }
    }
  },
})

