
import {
  IonContent, IonHeader, IonPage, IonCardTitle, IonCardSubtitle, IonToolbar,
  IonCard, IonCardHeader, IonCardContent, IonButtons, IonButton, IonIcon, IonItem,
  IonLabel, IonInput, IonNote, IonFooter
} from '@ionic/vue';
import { checkmarkCircleSharp } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ClockingInPage',

  components: {
    IonContent, IonHeader, IonPage, IonToolbar, IonButtons, IonButton, IonFooter,
    IonIcon, IonItem, IonLabel, IonInput, IonNote, IonCard, IonCardContent, IonCardHeader,
    IonCardTitle, IonCardSubtitle
  },

  data () {
    return {
      // Ion icons
      checkmarkCircleSharp
    }
  },

  computed: {
    //
  },

  methods: {
    returnToHome: function () {
      this.$router.back()
    }
  }
});
