import { Module } from 'vuex'
import { getDeviceInfo } from '@/utilities';
import { IDeviceModel, ITokenModel } from '@esse-group/shared';

export interface IDeviceStoreState {
  device: IDeviceModel | undefined
}

export const DeviceStore: Module<IDeviceStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    device: undefined
  },

  mutations: {
    setDeviceValue (state, device: IDeviceModel) {
      console.debug('DeviceStore - setDeviceValue', { device })
      state.device = device
    },
    setFCMTokenValue (state, token: string) {
      console.debug('DeviceStore - setFCMTokenValue', { token });

      if (state.device != undefined) {
        (state.device as IDeviceModel).token = token
          ? { 
            createdTs: Math.floor((new Date()).valueOf() / 1000),
            provider: 'firebase',
            token
          } as ITokenModel
          : undefined
      }
    }
  },

  actions: {
    async initialize ({ commit }) {
      console.debug('DeviceStore - initialize')
  
      try {
        const device = await getDeviceInfo()
        console.debug('DeviceStore - initialize - device information', { device })

        commit('setDeviceValue', device)
      } catch(error) {
        console.error('DeviceStore - initialize - error getting the device information', { error })
      }

      console.debug('DeviceStore - initialize - done')
    }
  }
}
