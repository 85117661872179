
import { defineComponent } from 'vue';
import { BrowserMultiFormatReader, Exception } from "@zxing/library";
import { IonButton } from '@ionic/vue';
import { App } from '@capacitor/app'

export default defineComponent({
  name: 'CameraScanner',

  components: { IonButton },

  emits: ['decodeQrCode', 'cameraIsEnable'],

  data() {
    return {
      isLoading: true,
      codeReader: new BrowserMultiFormatReader(),
      onResume: false,
      onResult: true,
      isMediaStreamAPISupported: navigator && navigator.mediaDevices && "enumerateDevices" in navigator.mediaDevices,
    };
  },

  created() {
    App.addListener('resume', async () => {
      this.onResult = true
      this.initCamera()
      this.onResume = false
    })
  },

  ionViewWillEnter() {
    this.refreshCamera()
  },

  mounted() {
    console.log('CameraScanner - mounted')

    this.initCamera()
  },

  beforeUnmount() {
    this.codeReader.reset();
  },

  methods: {
    refreshCamera() {
      const scanner = this.$refs.scanner as HTMLVideoElement
      console.debug(scanner);
      if (scanner && typeof (scanner as any).stop === 'function') {
        (scanner as any).stop();
      }
      this.isLoading = true
      this.codeReader.reset();
      this.initCamera()
    },

    initCamera() {
      console.debug('CameraScanner - initCamera')
      if (!this.isMediaStreamAPISupported) {
        throw new Exception("Media Stream API is not supported")
      }

      this.start()

      const scanner = this.$refs.scanner as HTMLVideoElement
      scanner.controls = false
      console.debug('CameraScanner - scanner', scanner)
      scanner.oncanplay = () => {
        console.debug('CameraScanner - scanner - oncanplay', scanner)
        this.isLoading = false;
        this.$emit('cameraIsEnable')
      };
    },

    start() {
      console.debug('CameraScanner - start')
      const scanner = this.$refs.scanner as HTMLVideoElement
      console.debug('CameraScanner - codeReader', this.codeReader)
      this.codeReader.decodeFromVideoDevice(null, scanner, (result) => {
        if (result && this.onResult) {
          this.onResult = false
          this.$emit('decodeQrCode', result)
        }
      })
    },
  },
})

