import config from '@/../google-services.json'
import { IClockingInModel, IUserModel } from '@esse-group/shared'
import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

export const apiKey = config.apiKey

// Get the firebase instance
export const app = firebase.initializeApp(config)

// Get a Firestore instance
export const db = app.firestore()

// Get a Auth instance
export const auth = app.auth()

// Get a Auth instance
export const storage = app.storage()

// Get a Functions instance
export const functions = app.functions('europe-west3')

// Define the various collections
// PROD: users
// DEV: usersDevelopment
export const usersCollectionName = 'users'
export const usersCollection = db.collection(usersCollectionName) as firebase.firestore.CollectionReference<IUserModel>
export const userDocument = (id: string): firebase.firestore.DocumentReference<IUserModel> => {
  return usersCollection.doc(id) as firebase.firestore.DocumentReference<IUserModel>
}
export const usersClockingInCollection = (userId: string): firebase.firestore.CollectionReference<IClockingInModel> => userDocument(userId)
  .collection(
    // PROD: clockingIn
    // DEV: clockingInDevelopment
    'clockingIn'
  ) as firebase.firestore.CollectionReference<IClockingInModel>
