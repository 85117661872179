import { Module } from 'vuex'
import { loadingController } from '@ionic/vue';

export interface ILoaderStoreState {
  loader: any | undefined
}

export const LoaderStore: Module<ILoaderStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    loader: undefined
  },

  mutations: {
    setValue (state, loader) {
      console.debug('LoaderStore - setValue', { loader })
      state.loader = loader
    }
  },

  actions: {
    async present({ commit, dispatch, state }, message?: string) {
      console.debug('LoaderStore - present', { message })

      if (state.loader !== undefined) {
        await dispatch('dismiss')
      }

      const loader = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: message != undefined ? message : 'Caricamento...'
        })
      commit('setValue', loader)
      loader.present()
    },
    async dismiss({ commit, state }) {
      console.debug('LoaderStore - dismiss')

      if (state.loader !== undefined) {
        await state.loader.dismiss()
      }
      commit('setValue', undefined)
    }
  }
}
