import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ec8457e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { slot: "helper" }
const _hoisted_3 = { style: {"display":"flex","width":"100%","align-items":"center"} }
const _hoisted_4 = { slot: "helper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { id: "auth-page" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_img, {
              class: "img",
              src: _ctx.logoImage
            }, null, 8, ["src"]),
            _createVNode(_component_ion_item, {
              fill: "solid",
              "no-lines": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { position: "fixed" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Email")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  ref: "email",
                  placeholder: "La tua email",
                  autocapitalize: "off",
                  autocomplete: "email",
                  enterkeyhint: "next",
                  inputmode: "email",
                  maxlength: "255",
                  mode: "ios",
                  name: "email",
                  spellcheck: false,
                  type: "email",
                  modelValue: _ctx.email,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                  "clear-input": true,
                  onKeyup: _withKeys(_ctx.onNext, ["enter"]),
                  onIonBlur: _ctx.v$.email.$touch
                }, null, 8, ["modelValue", "onKeyup", "onIonBlur"]),
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.email.$errors, (error) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: error.$uid
                    }, [
                      (error.$validator == 'email')
                        ? (_openBlock(), _createBlock(_component_ion_note, {
                            key: 0,
                            color: "danger"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("L'email non è valida")
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (error.$validator == 'required')
                        ? (_openBlock(), _createBlock(_component_ion_note, {
                            key: 1,
                            color: "danger"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("L'email è richiesta")
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, {
              fill: "solid",
              "no-lines": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { position: "fixed" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Password")
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_ion_input, {
                    ref: "password",
                    placeholder: "La tua password",
                    autocapitalize: "off",
                    autocomplete: "current-password",
                    enterkeyhint: "enter",
                    inputmode: "text",
                    maxlength: "255",
                    name: "password",
                    mode: "ios",
                    spellcheck: false,
                    type: _ctx.isShowingPassword ? 'text' : 'password',
                    modelValue: _ctx.password,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                    onKeyup: _withKeys(_ctx.onNext, ["enter"]),
                    onIonBlur: _ctx.v$.password.$touch
                  }, null, 8, ["type", "modelValue", "onKeyup", "onIonBlur"]),
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.isShowingPassword ? _ctx.eyeOffOutline : _ctx.eyeOutline,
                    size: "medium",
                    onClick: _ctx.onTogglePasswordVisibility
                  }, null, 8, ["icon", "onClick"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.password.$errors, (error) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: error.$uid
                    }, [
                      (error.$validator == 'minLength')
                        ? (_openBlock(), _createBlock(_component_ion_note, {
                            key: 0,
                            color: "danger"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("La password deve contenere almeno " + _toDisplayString(_ctx.passwordMinLength) + " caratteri", 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (error.$validator == 'required')
                        ? (_openBlock(), _createBlock(_component_ion_note, {
                            key: 1,
                            color: "danger"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("La password è richiesta")
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, {
              style: {"--inner-padding-end":"0"},
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_buttons, {
                  slot: "end",
                  style: {"margin":"0"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      fill: "clear",
                      onClick: _ctx.onPasswordRecovery
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Password dimenticata?")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_button, {
              color: "primary",
              type: "submit",
              expand: "block",
              style: {"margin-right":"16px","margin-left":"16px","margin-top":"48px"},
              onClick: _ctx.onFormSubmit,
              disabled: _ctx.v$.$invalid
            }, {
              default: _withCtx(() => [
                _createTextVNode("Accedi")
              ]),
              _: 1
            }, 8, ["onClick", "disabled"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}