
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import store from './store';
import { IUserModel } from '@esse-group/shared';
import { IAuthenticationStoreState } from './store/authentication.store'

import InstallBanner from '@/components/InstallBanner.vue';
import { ToastType } from './store/toast.store';

const authStoreHelper = createNamespacedHelpers('auth')
const toastStoreHelper = createNamespacedHelpers('toast')

export default defineComponent({
  name: 'App',

  store: store,

  components: {
    IonApp, IonRouterOutlet, InstallBanner
  },

  data () {
    return {
      deferredPrompt: null as Event | null
    }
  },

  computed: {
    ...authStoreHelper.mapState<IAuthenticationStoreState>({
      user: (state: IAuthenticationStoreState) => state.user
    })
  },

  async created() {
    // Bind and listen the Firebase Auth authentication status
    console.debug('App - initializeFirebaseAuth')
    await this.initializeFirebaseAuth()
  },

  methods: {
    // Include the AuthenticationStore actions
    ...authStoreHelper.mapActions(['initializeFirebaseAuth', 'signOut']),
    ...toastStoreHelper.mapActions({ presentToast: 'present' }),
  },

  watch: {
    // Check if the user has been disabled while using the application
    user: {
      handler: async function (state: IUserModel | undefined | null) {
        console.log('App - user', state)
        if (state == undefined || typeof state !== 'object') {
          console.log('App - user - not valid')
          return this.$router.replace('/login')
        } else {
          if (!state.isEnabled) {
            console.log('App - user - not valid')
            await this.signOut()
            await this.presentToast({ message: "Accesso fallito - Utente disabilitato", type: ToastType.warning })
            return this.$router.replace('/login')
          } else {
            return this.$router.replace('/home')
          }
        }
      },
      immediate: true
    }
  },
});
