import { Device } from '@capacitor/device';
import { isPlatform } from '@ionic/vue';
import { DevicePlatform, IDeviceModel } from '@esse-group/shared';

export const getDeviceInfo = async (): Promise<Partial<IDeviceModel>> => {
  console.debug('getDeviceInfo - start')

  const info = await Device.getInfo()
  const id = await Device.getId()
  
  let platform: DevicePlatform | undefined = undefined
  if (isPlatform('ios')) { platform = DevicePlatform.IOS }
  else if (isPlatform('android')) { platform = DevicePlatform.ANDROID }
  else if (isPlatform('desktop')) { platform = DevicePlatform.WINDOWS }
  else if (isPlatform('electron')) { platform = DevicePlatform.MACOS }


  return {
    ...info,
    createdTs: Math.floor((new Date()).valueOf() / 1000),
    id: id.identifier,
    latestLoginTs: Math.floor((new Date()).valueOf() / 1000),
    os: info.operatingSystem,
    platform,
    updatedTs: Math.floor((new Date()).valueOf() / 1000)
  }
}
