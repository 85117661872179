import { createStore } from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import { AuthenticationStore } from './authentication.store'
import { DeviceStore } from './device.store'
import { LoaderStore } from './loader.store'
import { ToastStore } from './toast.store'

export default createStore({
  modules: {
    auth: AuthenticationStore,
    loader: LoaderStore,
    toast: ToastStore,
    device: DeviceStore,
  },

  mutations: vuexfireMutations
})
