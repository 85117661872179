import { auth, db, userDocument } from '@/utilities';
import { IUserModel, IDeviceModel, IClockingInModel, IAddressModel, IQRCodeWorkingSiteModel } from '@esse-group/shared';
import { Module } from 'vuex';
import { firestoreAction } from 'vuexfire';
import firebase from 'firebase'
import { usersClockingInCollection } from '@/utilities/firebase.utility';
import { v4 as UUID } from 'uuid'
import pkgInfo from '../../package.json'

export interface IAuthenticationStoreState {
  authUser: firebase.User | null | undefined;
  user?: IUserModel | null | undefined;
}

export const AuthenticationStore: Module<IAuthenticationStoreState, unknown> = {
  namespaced: true,

  state: {
    authUser: undefined,
    user: undefined,
  },

  getters: {
    initialized: (state): boolean => {
      return state.user != undefined
    },
  },

  mutations: {
    setAuthUser(state, user: firebase.User | null) {
      state.authUser = user
    },
    setUser (state, payload?: IUserModel) {
      console.debug('AuthStore - setUser', { state, payload })
      state.user = payload != undefined ? payload : undefined
    },
  },

  actions: {
    // MARK: Initializations
    async initializeFirebaseAuth({ dispatch, commit }) {
      console.debug('AuthenticationStore - initializeFirebaseAuth');

      (auth.onAuthStateChanged as any)((user: firebase.User) => {
        console.debug('AuthenticationStore - bindAuthUser - received value', { user })
        if (user && typeof user === 'object') {
          commit('setAuthUser', user)
          dispatch('bindUserRef')
          dispatch('updateDeviceInformation')
        } else {
          commit('setAuthUser', null)
          dispatch('unbindUserRef')
        }
      })
    },

    // MARK: Listeners actions
    bindUserRef: firestoreAction(async ({ state, bindFirestoreRef }): Promise<any> => {
      console.debug('AuthenticationStore - bindUserRef', { id: state.authUser != undefined ? state.authUser.uid : undefined })
      if (state.authUser != undefined) {
        return bindFirestoreRef('user', userDocument(state.authUser.uid), { wait: true, reset: true })
      }
    }),

    unbindUserRef: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('AuthenticationStore - unbindUserRef')
      unbindFirestoreRef('user')
    }),

    // MARK: Authentication actions
    async emailSignIn(_, params: { email: string; password: string }): Promise<boolean> {
      console.debug('AuthenticationStore - emailSignIn', { email: params.email })

      try {
        const credential = await auth.signInWithEmailAndPassword(params.email, params.password)
        console.debug('AuthenticationStore - emailSignIn - credential', { credential })
      } catch (error) {
        console.debug('AuthenticationStore - emailSignIn - error', { error })
        console.debug(error)

        return false
      }

      return true
    },

    async sendEmailPasswordRecovery(_, params: { email: string }): Promise<boolean> {
      console.debug('AuthenticationStore - sendEmailPasswordRecovery', { email: params.email })

      try {
        await auth.sendPasswordResetEmail(params.email)
      } catch (error) {
        console.debug('AuthenticationStore - sendEmailPasswordRecovery - error', { error })
        console.debug(error)

        return false
      }

      return true
    },

    async signOut({ dispatch, commit }): Promise<void> {
      console.debug('AuthenticationStore - signOut')

      try {
        commit('setAuthUser', null)
        commit('setUser', null)
        dispatch('unbindUserRef')
        await auth.signOut()
        db.clearPersistence()
      } catch (error) {
        console.debug('AuthenticationStore - signOut - error', { error })
        console.debug(error)
      }
    },

    async createClockingIn (
      { state, rootState },
      params: {
        qrCodeWorkingSite: IQRCodeWorkingSiteModel,
        isClockingOut: boolean,
        userId: string
      }
    ): Promise<boolean> {
      console.debug('UsersStore - createClockingIn', { params })

      try {
        if (state.authUser != undefined) {
          let address: IAddressModel | undefined = undefined

          if (
            params.qrCodeWorkingSite.a !== undefined
            && params.qrCodeWorkingSite.lat !== undefined
            && params.qrCodeWorkingSite.lng !== undefined
          ) {
            address = {
              address: params.qrCodeWorkingSite.a,
              lat: params.qrCodeWorkingSite.lat,
              lng: params.qrCodeWorkingSite.lng
            }
          }

          if (address == undefined) { return false }

          const payload: IClockingInModel = {
            id: UUID(),
            createdTs: (new Date()).valueOf() / 1000,
            userId: params.userId,
            position: address
          }

          if (params.qrCodeWorkingSite.oi != undefined) {
            payload.orderId = params.qrCodeWorkingSite.oi
          }

          if (params.qrCodeWorkingSite.i != undefined) {
            payload.workingSiteId = params.qrCodeWorkingSite.i
          }

          payload.isClockingOut = params.isClockingOut

          if (pkgInfo && pkgInfo.version) {
            payload.appVersion = pkgInfo.version
          }
          if (rootState && (rootState as any).device) {
            payload.device = (rootState as any).device
          }

          await usersClockingInCollection(state.authUser.uid)
            .doc(payload.id)
            .set(JSON.parse(JSON.stringify(payload)))
          console.log('UsersStore - createClockingIn - succeded', payload)

          return true
        } else {
          return false
        }
      } catch (error) {
        console.error('UsersStore - createClockingIn - error creating the clocking in', { params, error })
      }

      return false
    },

    // MARK: Preferences actions

    async updateDeviceInformation({ state, rootState }) {
      const deviceInfo: IDeviceModel | undefined = (rootState as any)['device'].device;
      console.debug('AuthenticationStore - updateDeviceInformation', { deviceInfo })

      if (state.authUser == undefined || state.user == undefined || deviceInfo == undefined || deviceInfo.id == undefined) {
        console.debug('AuthenticationStore - updateDeviceInformation - user or device undefined')
        return
      }

      try {
        if (state.user.devices == undefined || !Object.keys(state.user.devices).includes(deviceInfo.id)) {
          await userDocument(state.authUser.uid)
            .update(JSON.parse(JSON.stringify({
              [`devices.${deviceInfo.id}`]: deviceInfo
            })))
        } else {
          await userDocument(state.authUser.uid)
            .update(JSON.parse(JSON.stringify({
              [`devices.${deviceInfo.id}.latestLoginTs`]: deviceInfo.latestLoginTs,
              [`devices.${deviceInfo.id}.name`]: deviceInfo.name,
              [`devices.${deviceInfo.id}.updatedTs`]: deviceInfo.updatedTs,
              [`devices.${deviceInfo.id}.token`]: deviceInfo.token,
            })))
        }
      } catch (error) {
        console.error('AuthenticationStore - updateDeviceInformation - error', { error })
        console.error(error)
      }
    }
  }
}
